.login-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-box {
    width: 500px;
    padding: 20px;
    border-radius: 10px;
    background-color: $off_white;
}

.login-logo {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-bottom: 30px;
}

.login-bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .login-logo {
        width: 150px;
        height: 150px;
        object-fit: contain;
        margin-bottom: 20px;
    }
    .login-box {
        width: 90%;
    }
}
