@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,700;1,400&family=Oswald:wght@400;500;700&family=Roboto:wght@300;400;500;700&display=swap');
@import './globalVariable.scss';
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', 'Arimo', sans-serif;
    background-color: $dark_grey;
}

a {
    color: $blue;
    text-decoration: none;
}

@import './login.scss';
@import './form.scss';
@import './loading.scss';
@import './search.scss';
@import './user.scss';
@import './pagnation.scss';
@import './modal.scss';
@import './order.scss';
@import './invoicePrint.scss';
