.newuser-roles {
    display: flex;
    justify-content: center;
}

.user-box {
    display: flex;
    padding: 20px;
}

.user-left {
    width: 200px;
}

.supplier-paper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.user-avatar {
    color: #000;
    background-color: #32e2a0;
}
