.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-content {
    width: 500px;
    height: 500px;
    box-shadow: 24px;
    border-radius: 20px;
    padding: 50px;
    overflow: scroll;
}

.modal-content-light {
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #000;
    color: black;
}

.modal-content-dark {
    background-color: rgba(0, 0, 0, 0.9);
    border: 1px solid #fff;
    color: white;
}
