.invoice-header-container {
  display: flex;
}

.invoice-header-logo {
  width: 20%;
}

.invoice-header-logo-size {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.invoice-header-invoice-no {
  width: 30%;
}

.invoice-header-name {
  width: 50%;
}

.invoice-header-top-table {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}

.invoice-container {
  padding: 30px;
  width: 800px;
}

.invoice-to-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 50px;
}

.invoice-to-item {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.invoice-to-details {
  margin-top: 10px;
  border: 1px solid gray;
  padding: 10px;
  font-size: 12px;
  flex-grow: 1; /* Allow the details section to grow to fill the available space */
}

/* Make paragraphs stretch to fill available space */
.invoice-to-details p {
  flex-grow: 1;
  margin: 0; /* Remove default margin */
}

@page {
  margin-top: 50px;
  margin-bottom: 50px;
}
@media print {
  footer {
    display: none !important;
  }
}
