.order-review-header-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 6px;
    border: 1px solid #ccc;
}

.order-review-header-divider {
    width: 1px;
    background-color: #ccc;
}

.order-review-header-item {
    width: 100%;
    padding: 10px;
}

.order-review-header-icon {
    color: $grey;
    display: flex;
    align-items: center;
}

.order-review-header-title {
    margin-left: 10px;
}

.order-review-header-list {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.order-review-header-list-left {
    width: 130px;
}

.order-review-header-list-right {
    color: $grey;
}

.order-style-name-input {
    border-width: 0;
    font-size: 25px;
}

.order-review-total-box {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
}

.order-review-total-list {
    display: flex;
    gap: 10px;
    width: 300px;
}

.order-review-total-list-left {
    width: 200px;
    text-align: right;
}

.order-review-total-list-right {
    color: $grey;
}

.order-review-button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;
}

.noBottomBorder {
    & td,
    & th {
        border-bottom: none;
    }
}

.dot-border {
    width: 100%;
    border: 1px dashed $primary;
    padding: 10px;
    white-space: pre-wrap;
}

.order-item-card {
    padding: 10px;
    background-color: $light_grey;
}

.order-product-items {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.order-edit-list-container {
    margin-top: 20px;
    margin-bottom: 20px;
}

.order-edit-list-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.door-direction-box {
    width: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.door-direction-item {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    border: 1px solid $light_grey;
    color: $light_grey;
    cursor: pointer;
    &:hover {
        border: 1px solid $grey;
        color: $grey;
    }
}

.door-direction-item-active {
    border: 1px solid $primary;
    color: $primary;
}

.finished-end-box {
    width: 95px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-search-box {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.order-item-pic-name {
    text-align: center;
    width: 100%;
    font-size: 12px;
    margin-top: 10px;
}

.order-product-add-style {
    max-width: 80%;
    height: 90vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    background-color: white;
    overflow: scroll;
}

.order-product-add-style-box {
    // padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
}

.order-product-add-style-category {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 30px;
}

.order-product-sticky-header {
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}

.order-product-add-style-category-item {
    display: flex;
    flex-direction: column;
}

.order-product-add-style-category-item-title {
    margin-top: 20px;
    margin-bottom: 20px;
}

.order-product-add-style-category-item-box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.door-color-list-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
}

.order-paper {
    margin: 30px;
    margin: 30px;
    padding: 50px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.signin-header {
    margin-top: 50px;
    text-align: center;
}

.returnorder-button-group-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.reuseable-container {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
