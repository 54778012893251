.form-input {
    padding: 5px 0;
    margin-bottom: 20px;
}

.form-submit-button {
    margin-top: 20px;
}

.button-box {
    display: flex;
    gap: 10px;
}

.input-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.app-input-src {
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-pic {
    object-fit: contain;
    max-height: 100px;
}

.display-item-pic {
    object-fit: contain;
    max-height: 50px;
}

.display-door-color-pic {
    object-fit: contain;
    max-height: 150px;
}

.display-contact-pic {
    object-fit: contain;
    max-height: 200px;
}
